import moment from "moment";

export default {
  data() {
    return {
      content_in_processing: false,
      // 10s
      reconnect_interval: 10000,
    };
  },
  computed: {
    shared_layout() {
      return this.$root.global_layout;
    },
  },
  filters: {
    toLocalTime(t) {
      return moment.utc(t).local();
    },
    formatLocalTime(t) {
      return t.format("YYYY-MM-DD HH:mm:ss");
    },
    task_status_zhcn(v) {
      if (v === "finished") {
        return "完成";
      } else if (v === "waiting") {
        return "等待中";
      } else if (v === "running") {
        return "运行中";
      } else if (v === "failed") {
        return "失败";
      } else if (v === "submitted") {
        return "已提交";
      }
    },
    worker_status_zhcn(v) {
      if (v === "idle") {
        return "全空闲";
      } else if (v === "busy") {
        return "满载";
      } else if (v === "normal") {
        return "运行中";
      } else if (v === "allbusy") {
        return "均满载";
      } else if (v === "dead") {
        return "无worker";
      } else {
        return "缺失";
      }
    },
  },
  methods: {
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function(match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    showApiMsg(msg, type) {
      this.$message({
        message: msg,
        type: type,
      });
    },
    str2bytes(str) {
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
        // bytes[i] = str.codePointAt(i);
      }
      return bytes;
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
};
