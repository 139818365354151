import Vue from "vue";
import Vuex from "vuex";
import apiURL from "@/data/api";

Vue.use(Vuex);

import axiosMethods from "@/plugins/axiosMethods.js";

Vue.use(axiosMethods);

export default new Vuex.Store({
  state: {
    app_name: "Cloud-DNDC",
    admin_name: "admin",
    default_active_page: "/dashboard",
    lang_status: "zh_cn",
    session_id: null,
    // user info
    user: {
      token: "",
      authenticated: false,
      statistics: {
        datahome_dir: "",
        datahome_size: 0,
        id: 0,
        name: "",
        task_all: 0,
        task_failed: 0,
        task_finished: 0,
        task_records: [],
        task_running: 0,
      },
    },
    users: [],
    n_users: 0,

    task_list: [],
    n_tasks: 0,
    //api call info
    api_error_msg: "",
    api_success_msg: "",
    sys_msg: "",
    global_err_msg: "",

    // 用于存放实时的计算节点信息
    nodes_status: {},
  },
  getters: {
    has_error: (state) => {
      return state.global_err_msg.length > 0;
    },
    access_token: (state) => {
      return state.user.token;
    },
    headers4reqs: (state) => {
      return {
        Authorization: "Bearer " + state.user.token,
      };
    },
    task_dots: (state) => {
      var td = [];
      state.user.statistics.task_records.forEach((element) => {
        td.push({
          x: new Date(element),
          y: 1,
        });
      });
      return td;
    },
    user_dict: (state) => {
      var ud = {};
      for (let i = 0; i < state.users.length; i++) {
        ud[state.users[i].id] = state.users[i].name;
      }
      return ud;
    },
    user_id: (state) => {
      return state.user.statistics.id;
    },
    node_ids: (state) => {
      const ids = Object.keys(state.nodes_status).sort();
      return ids;
    },
  },
  mutations: {
    CHANGE_DEFAULT_PAGE(state, msg) {
      state.default_active_page = msg;
    },
    CHANGE_SESSION_ID(state, msg) {
      state.session_id = msg;
    },
    UPDATE_API_ERROR(state, msg) {
      // state.api_error_msg = msg;
      console.log(msg);
      return state;
    },
    UPDATE_API_SUCCESS(state, msg) {
      return state, msg;
      // state.api_success_msg = msg;
    },
    CLEAR_API_ERROR(state) {
      state.api_error_msg = "";
      state.api_success_msg = "";
    },
    LOAD_GET_API(state) {
      return state;
    },
    LOAD_POST_API(state) {
      return state;
    },
    UPDATE_API_DATA_NULL(state, msg) {
      state.api_success_msg = msg;
    },
    SET_USER_TOKEN(state, msg) {
      state.user.token = msg;
    },
    SET_TOKEN_VALID(state) {
      state.user.authenticated = true;
    },
    RESET_TOKEN_VALID(state) {
      state.user.authenticated = false;
    },
    SET_USER_NAME(state, msg) {
      state.user.name = msg;
    },
    SET_USER_EXTRA_INFO(state, msg) {
      state.user.statistics = msg;
    },
    SET_TASK_LIST(state, msg) {
      state.task_list = msg;
    },
    SET_TASK_N(state, msg) {
      state.n_tasks = msg;
    },
    SET_USERS_LIST(state, msg) {
      state.users = msg.users;
      state.n_users = msg.n_users;
    },

    SET_NODE_STATUS(state, msg) {
      // state.nodes_status[node_id] = msg.status;
      this._vm.$set(state.nodes_status, msg.id, msg.status);
    },

    REMOVE_NODE_STATUS(state, node_id) {
      // delete state.nodes_status[node_id]
      this._vm.$delete(state.nodes_status, node_id);
    },

    set_err_msg(state, err_msg) {
      state.global_err_msg = err_msg;
    },
    set_company_list(state, company_list) {
      state.company_list = company_list;
    },
    set_language(state, language) {
      state.lang_status = language;
    },
  },
  actions: {
    getLangChange(context, lang) {
      context.commit("set_language", lang);
    },
    setSessionId(context, nv) {
      context.commit("CHANGE_SESSION_ID", nv);
    },
    switchDefaultPage(context, nv) {
      context.commit("CHANGE_DEFAULT_PAGE", nv);
    },
    updateUserToken(context, nv) {
      context.commit("SET_USER_TOKEN", nv.access_token);
    },
    confirmUserLogin(context, nv) {
      context.commit("SET_USER_TOKEN", nv.access_token);
      context.commit("SET_TOKEN_VALID");
      localStorage.setItem("access_token", JSON.stringify(nv));
    },
    updateNodeInfo(context, nv) {
      context.commit("SET_NODE_STATUS", JSON.parse(nv));
    },
    clearNodeInfo(context, nv) {
      context.commit("REMOVE_NODE_STATUS", nv);
    },
    getNodesStatistics(context) {
      let count = 0;
      let busy_count = 0;
      let idle_count = 0;
      let normal_count = 0;
      for (let key in context.state.nodes_status) {
        const time_now = new Date().getTime() / 1000;
        if (time_now - context.state.nodes_status[key].time > 30) {
          // 30秒之前的历史数据不要
          continue;
        }
        ++count;
        if (context.state.nodes_status[key].workers_status === "busy") {
          ++busy_count;
        } else if (context.state.nodes_status[key].workers_status === "idle") {
          ++idle_count;
        } else if (
          context.state.nodes_status[key].workers_status === "normal"
        ) {
          ++normal_count;
        }
      }
      const nodes_statistics = {
        count: count,
        busy_count: busy_count,
        idle_count: idle_count,
        normal_count: normal_count,
      };
      console.log("nodes_statistics ", nodes_statistics);
      return nodes_statistics;
    },
    getNodeInfo(context, node_id) {
      if (node_id in context.state.nodes_status) {
        const time_now = new Date().getTime() / 1000;
        if (time_now - context.state.nodes_status[node_id].time > 30) {
          //删除过老的节点并且返回null
          context.commit("REMOVE_NODE_STATUS", node_id);
          return null;
        } else {
          return context.state.nodes_status[node_id];
        }
      } else {
        return null;
      }
    },
    getNodeAvailability(context, node_id) {
      const node_info = context.dispatch("getNodeInfo", node_id);
      if (node_info !== null) {
        return node_info.workers_status;
      } else {
        return "dead";
      }
    },
    async removeUserToken(context) {
      localStorage.removeItem("access_token");
      context.commit("SET_USER_TOKEN", "");
      context.commit("RESET_TOKEN_VALID");
      return true;
    },
    async loadUserLogin(context) {
      try {
        let retrievedCreds = JSON.parse(localStorage.getItem("access_token"));
        if (
          retrievedCreds.access_token.length > 0 &&
          retrievedCreds.name.length > 0
        ) {
          context.dispatch("updateUserToken", retrievedCreds);
          await this._vm
            .$get_api(
              apiURL.user.validate,
              context,
              this.getters.headers4reqs, {}
            )
            .then((res) => {
              if (res.id > 0) {
                context.dispatch("confirmUserLogin", retrievedCreds);
                return res;
              }
            });
          // 如果信息空，获取信息
          if (
            context.state.user.statistics.name === "" &&
            context.state.user.authenticated === true
          ) {
            context.dispatch("getCurrentUserInfo");
          }
          if (retrievedCreds.name === context.state.admin_name) {
            this._vm
              .$get_api(apiURL.user.all, context, this.getters.headers4reqs, {})
              .then((value) => {
                context.commit("SET_USERS_LIST", value);
                return value;
              });
          }
        }
      } catch (error) {
        console.log("no local token info");
      }
      // 获取用户列表信息
    },

    loadGetAPI(context, api_info) {
      let api_name = api_info.api_name;
      console.log(api_name, context.commit("LOAD_GET_API"));
      return this._vm
        .$get_api(api_name, context, this.getters.headers4reqs, {})
        .then((value) => {
          return value;
        });
    },
    getCurrentUserInfo(context) {
      this._vm
        .$get_api(apiURL.user.me, context, this.getters.headers4reqs)
        .then((res) => {
          // console.log(res);
          res.task_records.sort((a, b) => {
            // return new Date(a) - new Date(b)
            return a > b;
          });
          context.commit("SET_USER_EXTRA_INFO", res);
        });
    },
    async getTaskList(context) {
      this._vm
        .$get_api(apiURL.task.list, context, this.getters.headers4reqs)
        .then((res) => {
          console.log(res);
          context.commit("SET_TASK_LIST", res.tasks);
          context.commit("SET_TASK_N", res.n_tasks);
        });
    },
  },
  modules: {},
});