import apiURL from "@/data/api";
import axios from "axios";
// axios.defaults.baseURL = apiURL.baseURL + apiURL.stage;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + apiURL.stage;
const maxTimeout = 100000;
const axiosInstances = [
  axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL + apiURL.stage,
    timeout: maxTimeout,
  }),
  axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: maxTimeout,
  }),
];

let axiosMethods = {};

axiosMethods.install = function (Vue) {
  (Vue.prototype.$get_api_direct = function (
    api_name,
    context = null,
    headers = {},
    params = {}
  ) {
    const ctxCommit = context !== null ? context.commit : this.$store.commit;
    let axiosInstance = axiosInstances[1];

    return axiosInstance
      .get(
        `${api_name}`, {
          params: params,
          headers: headers,
        }, {
          timeout: 50000,
        }
      )
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `GET: ${api_name} 调用失败。`);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： GET: ${api_name} 调用成功。`
          );
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        console.log(error);
        ctxCommit(
          "UPDATE_API_ERROR",
          `GET: ${api_name} 调用出现未知错误 ${error}.`
        );
        throw error;
      });
  }),
  (Vue.prototype.$get_api = function (
    api_name,
    context = null,
    headers = {},
    params = {}
  ) {
    const ctxCommit = context !== null ? context.commit : this.$store.commit;
    let axiosInstance = axiosInstances[0];

    return axiosInstance
      .get(
        `${api_name}`, {
          params: params,
          headers: headers,
        }, {
          timeout: 50000,
        }
      )
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `GET: ${api_name} 调用失败。`);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： GET: ${api_name} 调用成功。`
          );
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        console.log(error);
        ctxCommit(
          "UPDATE_API_ERROR",
          `GET: ${api_name} 调用出现未知错误 ${error}.`
        );
        throw error;
      });
  }),
  (Vue.prototype.$get_download_blob_api = function (
    api_name,
    context = null,
    headers = {},
    params = {}
  ) {
    const ctxCommit = context !== null ? context.commit : this.$store.commit;
    let axiosInstance = axiosInstances[0];

    return axiosInstance
      .get(
        `${api_name}`, {
          params: params,
          headers: headers,
          responseType: "arraybuffer",
        }, {
          timeout: 50000,
        }
      )
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `GET: ${api_name} 调用失败。`);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： GET: ${api_name} 调用成功。`
          );
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        console.log(error);
        ctxCommit(
          "UPDATE_API_ERROR",
          `GET: ${api_name} 调用出现未知错误 ${error}.`
        );
        throw error;
      });
  }),
  (Vue.prototype.$post_api = function (
    api_name,
    props_data,
    context = null,
    headers = {}
  ) {
    let axiosInstance = axiosInstances[0];
    const ctxCommit = context !== null ? context.commit : this.$store.commit;
    return axiosInstance({
        method: "POST",
        url: `${api_name}`,
        headers: headers,
        data: props_data,
      })
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `POST ${api_name} 调用失败。`);
          ctxCommit("UPDATE_API_ERROR", res.data.errorInfo.errorMessage);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： POST: ${api_name} 调用成功。`
          );
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        ctxCommit(
          "UPDATE_API_ERROR",
          `POST: ${api_name} 调用出现未知错误 ${error}。`
        );
        throw error;
      });
  }),
  (Vue.prototype.$put_api = async function (
    api_name,
    props_data,
    context = null,
    headers = {},
    params = {}
  ) {
    const ctxCommit = context !== null ? context.commit : this.$store.commit;
    // const state = context !== null ? context.state : this.$store.state;
    let axiosInstance = axiosInstances[0];
    let res = await axiosInstance({
        method: "PUT",
        url: `${api_name}`,
        headers: headers,
        data: props_data,
        params: params,
      })
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `PUT ${api_name} 调用失败。`);
          ctxCommit("UPDATE_API_ERROR", res.data.errorInfo.errorMessage);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： PUT: ${api_name} 调用成功。`
          );
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        console.log(error);
        ctxCommit(
          "UPDATE_API_ERROR",
          `PUT: ${api_name} 调用出现未知错误 ${error}。`
        );
        throw error;
      });

    return res;
  }),
  (Vue.prototype.$delete_api = function (
    api_name,
    context = null,
    headers = {},
    params = {}
  ) {
    let axiosInstance = axiosInstances[0];

    const ctxCommit = context !== null ? context.commit : this.$store.commit;

    return axiosInstance({
        method: "DELETE",
        url: `${api_name}`,

        headers: headers,
        params: params,
      })
      .then((res) => {
        if (res.data.status == false) {
          ctxCommit("UPDATE_API_ERROR", `DELETE ${api_name} 调用失败。`);
          ctxCommit("UPDATE_API_ERROR", res.data.errorInfo.errorMessage);
        } else {
          ctxCommit(
            "UPDATE_API_SUCCESS",
            `调试信息： DELETE: ${api_name} 调用成功。`
          );
          ctxCommit("UPDATE_API_DATA_NULL", "完成 DELETE: 返回数据为空");
          return true;
        }
        return res.data;
      })
      .then((res) => {
        ctxCommit("CLEAR_API_ERROR");
        return res;
      })
      .catch(function (error) {
        console.log(error);
        ctxCommit(
          "UPDATE_API_ERROR",
          `DELETE: ${api_name} 调用出现未知错误 ${error}。`
        );
        throw error;
      });
  });
};
export default axiosMethods;