import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axiosMethods from "./plugins/axiosMethods.js";
import baseMixin from "@/mixins/base";
import VueI18n from "vue-i18n";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "vue-calendar-heatmap/dist/vue-calendar-heatmap.css";

import variables from "@/style/system-variables.scss";
const shared_layout = {
  // block_space: parseInt(variables.block_space, 10),
  // text_color: variables.text_color,
  menu_bg_color: variables.menu_bg_color,
  colors: variables,
  node_scale: variables.node_scale,
};

Vue.use(VueI18n);
Vue.use(axiosMethods);
Vue.use(ElementUI);
Vue.mixin(baseMixin);
Vue.config.productionTip = false;

import zhcn from "@/locale/zh-cn";
import en from "@/locale/en";
// import { locale } from "core-js";
const messages = {
  en: en,
  zh_cn: zhcn,
};
const i18n = new VueI18n({
  locale: "zh_cn", // set locale
  messages, // set locale messages
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    global_layout: shared_layout,
  },
  i18n,
}).$mount("#app");
