import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);
const routes = [{
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    component: () => import('@/views/Dashboard.vue'),
    children: [{
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: 'user-center',
        name: 'UserCenter',
        component: () => import('../views/UserCenter.vue'),
      },
      {
        path: "users-management",
        name: "UsersManagement",
        component: () =>
          import("../views/UsersManagement.vue")
      },
      {
        path: "computation-nodes",
        name: "ComputationNodes",
        component: () =>
          import("../views/ComputationNodes.vue")
      },

      {
        path: "user-manual",
        name: "Manual",
        component: () =>
          import("../views/Manual.vue")
      },
      {
        path: "map-view",
        name: "MapView",
        component: () =>
          import("../views/MapView.vue")
      },
      {
        path: "downloads",
        name: "Downloads",
        component: () =>
          import("../views/Downloads.vue")
      },
      {
        path: "api-doc",
        name: "ApiDoc",
        component: () =>
          import("../views/ApiDoc.vue")
      },
      {
        path: 'task-center',
        component: () => import('../views/Task/TaskContainer.vue'),
        children: [{
            path: '',
            name: 'TaskList',
            component: () => import('../views/Task/TaskCenter.vue'),
            props: true
          },
          {
            path: 'create',
            name: 'TaskCreate',
            component: () => import('../views/Task/TaskCreate.vue'),
          },
          {
            path: 'details/:id',
            name: 'TaskViewer',
            component: () => import('../views/Task/TaskViewer.vue'),
          }
        ]
      },

    ]
  },

  {
    path: "/map-view-public",
    name: "PublicMapView",
    component: () =>
      import("../views/MapView.vue")
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: () =>
      import("../views/Downloads.vue")
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "@/views/Login.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  console.log("转向 ", to)
  await store.dispatch("loadUserLogin");
  let isAuthenticated = store.state.user.authenticated;
  const public_views = ["PublicMapView", "Login", "Downloads"];
  if (!public_views.includes(to.name) && !isAuthenticated) next({
    name: "Login"
  });
  else next();
});


export default router;