<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "App",
  components: {},
  computed: {
    ...mapState(["api_error_msg", "api_success_msg", "sys_msg"]),
  },
  mounted() {
    this.setSessionId(this.uuidv4());
  },
  methods: {
    ...mapActions(["setSessionId"]),
  },
  watch: {
    api_error_msg(v) {
      if (v.length > 0) {
        this.showApiMsg(v, "error");
      }
    },
    api_success_msg(v) {
      if (v.length > 0) {
        this.showApiMsg(v, "success");
      }
    },
    sys_msg(v) {
      if (v.length > 0) {
        this.showApiMsg(v, "info");
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
